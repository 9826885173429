/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari <=12, IE)
  // https://caniuse.com/#search=IntersectionObserver
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    // console.log(`# IntersectionObserver is polyfilled!`)
  }
}
